* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background: #f1f5f8;
  color: #222;
}

.booklist {
  width: 90vw;
  max-width: 1170px;
  margin: 5rem auto;
  display: grid;
  gap: 2rem;
}

@media screen and (min-width: 768px) {
  .booklist {
    grid-template-columns: repeat(3, 1fr);
    align-items: start;
  }
}

.book {
  background: #fff;
  border-radius: 1rem;
  padding: 2rem;
  text-align: center;
  position: relative;
}

.book img {
  width: 100%;
  object-fit: cover;
}

.book h2 {
  margin-top: 1rem;
  font-size: 1rem;
}

.book h4 {
  color: #617d98;
  font-size: 0.75rem;
  margin-top: 0.5rem;
}

.number {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0.74rem;
  font-size: 1rem;
  font-weight: bolder;
  border-top-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  background: rgb(98, 231, 53);
}

h1 {
  text-align: center;
  margin-top: 4rem;
  text-transform: capitalize;
}
